import { render, staticRenderFns } from "./CustomSelectableFilters.vue?vue&type=template&id=054c4f12&scoped=true&"
import script from "./CustomSelectableFilters.vue?vue&type=script&lang=js&"
export * from "./CustomSelectableFilters.vue?vue&type=script&lang=js&"
import style0 from "./CustomSelectableFilters.vue?vue&type=style&index=0&id=054c4f12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054c4f12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseFilterMenu: require('/tmp/build_51aa5b7f/components/base/BaseFilterMenu.vue').default})
