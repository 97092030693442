
export default {
  props: {
    height: {
      type: String,
      default: '40px',
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    wordsToFilter: '',
  }),
  computed: {
    placeholderContent() {
      return this.placeholder
        ? this.placeholder
        : this.$t('base.search.placeholder.default');
    },
  },
};
