
import BaseFilterMenu from '../base/BaseFilterMenu.vue';
export default {
  components: { BaseFilterMenu },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    activeFilters: {
      type: Object,
      default: null,
    },
    clearFilters: {
      type: Boolean,
      deafult: false,
    },
  },
  data() {
    return {
      appliedFilters: {},
    };
  },
  computed: {
    noDefaultFilters() {
      return Object.keys(this.appliedFilters).some((key) =>
        this.appliedFilters[key].some((item) => item !== 'any'),
      );
    },
  },
  watch: {
    appliedFilters(newval) {
      this.onFiltersChange(newval);
    },
    clearFilters(value) {
      if (value) {
        this.clear();
        this.$emit('cleared-filters', true);
      }
    },
    activeFilters(newVal) {
      if (newVal) {
        this.appliedFilters = Object.assign({}, this.activeFilters);
      }
    },
  },
  mounted() {
    if (this.activeFilters) {
      this.appliedFilters = Object.assign({}, this.activeFilters);
    }
  },
  methods: {
    applyFilter({ filter, option }) {
      if (option.key !== 'any') this.removeOption(filter, 'any');
      if (this.appliedFilters[filter.key].some((item) => item === option.key)) {
        this.removeOption(filter, option.key);
        if (this.appliedFilters[filter.key].length === 0)
          this.appliedFilters[filter.key].push('any');
      } else if (filter.multiple && option.key !== 'any') {
        this.appliedFilters[filter.key].push(option.key);
      } else {
        this.appliedFilters[filter.key] = [option.key];
      }

      this.onFiltersChange();
    },
    removeOption(filter, key) {
      this.appliedFilters[filter.key] = this.appliedFilters[filter.key].filter(
        (item) => item !== key,
      );
    },
    onFiltersChange(filters = this.appliedFilters) {
      this.$emit('filters-changed', filters);
    },
    clear() {
      this.appliedFilters = Object.assign({}, this.activeFilters);
      this.onFiltersChange();
    },
  },
};
