
export default {
  components: {},
  mixins: [],
  props: {
    items: {
      type: Array,
      default: [],
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    tab: {
      get() {
        return this.tabIndex;
      },
      set(value) {
        this.$emit('update:tabIndex', value);
      },
    },
    getClass() {
      return this.index > 0
        ? this.index < this.items.length - 1
          ? 'fadeinout'
          : 'fadein'
        : 'fadeout';
    },
  },
  methods: {
    rightSwipe(value) {
      if (
        this.$refs.tabtab.$el.clientWidth - value.offsetX < 0 ||
        this.$refs.tabtab.$children[0]._data.scrollOffset < 0
      )
        this.index = 0;
      else this.index = this.items.length - 2;
    },
    leftSwipe(value) {
      const scrollOffset = this.$refs.tabtab.$children[0]._data.scrollOffset;
      setTimeout(() => {
        if (
          this.$refs.tabtab.$el.clientWidth - Math.abs(value.offsetX) < 0 ||
          scrollOffset > this.$refs.tabtab.$children[0]._data.scrollOffset
        )
          this.index = this.items.length - 1;
        else this.index = this.items.length - 2;
      }, 100);
    },
  },
  watch: {
    '$vuetify.breakpoint.xsOnly'(value) {
      if (value === true) this.index = this.tab;
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
