
export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    activeOptions: {
      type: Array,
      default: () => ['any'],
    },
  },
  computed: {
    filterLabel() {
      return this.activeOptions.some(
        (activeOption) => activeOption === 'any',
      ) || this.filter.multiple
        ? this.filter.label
        : this.filter.options.find(
            (option) => option.key === this.activeOptions[0],
          ).label;
    },
    filterClass() {
      return this.activeOptions.some((activeOption) => activeOption === 'any')
        ? 'text-root--text'
        : 'primary--text';
    },
  },
  methods: {
    selectFilter(option) {
      if (
        option.key !== 'any' ||
        (option.key === 'any' &&
          this.activeOptions.some((item) => item !== option.key))
      )
        this.$emit('filter-selected', { filter: this.filter, option });
    },
  },
};
