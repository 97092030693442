
import BaseFilterMenu from '../base/BaseFilterMenu.vue';
export default {
  components: { BaseFilterMenu },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    clearFilters: {
      type: Boolean,
      deafult: false,
    },
    defaultFilters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // default filters selected by default
      appliedFilters: Object.assign({}, this.defaultFilters),
      appliedFiltersCounter: 0,
      filtersModal: false,
      toggleSelect: {},
    };
  },
  computed: {
    tabs() {
      return ['marketplace', 'myofferings', 'mybids', 'collections'];
    },
    currentTab() {
      return this.tabs.includes(this.$route.path.split('/')[3])
        ? this.$route.path.split('/')[3]
        : '';
    },
  },
  watch: {
    // appliedFilters(newValue, oldValue) {
    //   this.filterSelected(newValue, filter.key);
    // },
  },
  methods: {
    handleClickOutside(event) {
      const selectableFilterParent = event.target.closest(
        'div.custom-selectable-filters__select',
      );
      let selectableFilterId;
      if (selectableFilterParent === null) {
        const menuContent = event.target.closest('div.v-menu__content');
        selectableFilterId = menuContent !== null ? 'menu-content' : null;
      } else {
        selectableFilterId = selectableFilterParent.querySelector(
          '[data-key="selectable-filter"]',
        ).dataset.id;
      }
      for (let key in this.toggleSelect) {
        if (
          this.toggleSelect[key] === true &&
          ![key, 'menu-content'].includes(selectableFilterId)
        )
          this.toggleSelect[key] = false;
      }
    },
    removeOption(filter, key) {
      this.appliedFilters[filter.key] = this.appliedFilters[filter.key].filter(
        (item) => item !== key,
      );
    },
    onFiltersChange() {
      const filters = {};
      let counter = 0;
      Object.keys(this.appliedFilters).forEach((filter) => {
        if (this.appliedFilters[filter] && this.appliedFilters[filter].length) {
          const appliedFilter = this.appliedFilters[filter];
          filters[filter] = appliedFilter;
          // dont count any as filter selected
          counter += appliedFilter.filter((option) => option !== 'any').length;
        }
      });
      this.appliedFilters = filters;
      this.setCounter(counter);
      this.$emit('filters-changed', Object.assign({}, this.appliedFilters));

      if (this.filtersModal === true) this.toggleModal();
    },
    clear() {
      this.appliedFilters = Object.assign({}, this.defaultFilters);
      this.onFiltersChange();
    },
    setCounter(counter) {
      this.appliedFiltersCounter = counter;
    },
    toggleModal() {
      this.filtersModal = !this.filtersModal;
    },
    // Any by default, if other filter selected -> remove any filter
    // if others filters selected and select any -> remove the rest leaving only any
    filterSelected(filters, key) {
      const multiple = this.filters.find(
        (filter) => filter.key === key,
      ).multiple;
      if (
        multiple &&
        filters.length > 1 &&
        filters.some((filter) => filter === 'any')
      ) {
        if (filters[filters.length - 1] === 'any')
          this.appliedFilters[key] = this.appliedFilters[key].filter(
            (filter) => filter === 'any',
          );
        else
          this.appliedFilters[key] = this.appliedFilters[key].filter(
            (filter) => filter !== 'any',
          );
      } else if (filters.length === 0 && this.defaultFilters[key] !== undefined)
        this.appliedFilters[key] = this.defaultFilters[key];
      else if (!multiple)
        this.appliedFilters[key] = this.appliedFilters[key].filter(
          (filter) => filter === filters[filters.length - 1],
        );
    },
  },
  mounted() {
    this.appliedFiltersCounter = Object.values(this.defaultFilters).reduce(
      (total, key) => {
        if (key.some((option) => option !== 'any')) total++;
        return total;
      },
      0,
    );
    if (this.appliedFiltersCounter > 0) this.onFiltersChange();
    this.toggleSelect = this.filters.reduce((result, filter) => {
      result[filter.key] = false;
      return result;
    }, {});
  },
};
