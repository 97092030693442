import { render, staticRenderFns } from "./BasePageTabs.vue?vue&type=template&id=eec682d4&scoped=true&"
import script from "./BasePageTabs.vue?vue&type=script&lang=js&"
export * from "./BasePageTabs.vue?vue&type=script&lang=js&"
import style0 from "./BasePageTabs.vue?vue&type=style&index=0&id=eec682d4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eec682d4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseNotificationBadge: require('/tmp/build_51aa5b7f/components/base/BaseNotificationBadge.vue').default})
