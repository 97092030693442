
import {
  MARKET_FILTERS,
  MARKET_FILTERS_DEFAULT,
  MY_BIDS_FILTERS,
  MY_OFFERINGS_BIDS_FILTERS_DEFAULT,
  MY_OFFERINGS_FILTERS,
} from '~/vars/market';
import BaseNavbar from '~/components/base/BaseNavbar.vue';
import SearchByWordsFilter from '~/components/commons/SearchByWordsFilter.vue';
import CustomFilters from '~/components/commons/CustomFilters.vue';
import CustomSelectableFilters from '~/components/commons/CustomSelectableFilters.vue';
import CustomSelectableFiltersCopy from '~/components/commons/CustomSelectableFiltersCopy.vue';
import MarketCompaniesFilter from '~/components/market/MarketCompaniesFilter.vue';
import { USER_OPTION_COMPANY } from '~/vars/api';

export default {
  scrollToTop: true,
  components: {
    SearchByWordsFilter,
    CustomFilters,
    BaseNavbar,
    CustomSelectableFilters,
    CustomSelectableFiltersCopy,
    MarketCompaniesFilter,
  },
  data() {
    return {
      toggleSelect: false,
      appliedFilters: {},
      filterByWords: '',
      collections: [],
      clearFilters: false,
      category: this.$t('market.base.all_products'),
      filtersModal: false,
      last: undefined,
      hasMoreItems: false,
      filteredProducts: [],
      teams: [],
    };
  },
  watch: {
    '$route.path'(value) {
      this.appliedFilters = {};
      this.clearFilters = true;
    },
    category(val) {
      const route = val.includes('collections') ? 'collections' : 'marketplace';
      this.$router.push(
        this.localePath({
          name: `market-preview-${route}`,
        }),
      );
    },
  },
  computed: {
    showFilters() {
      return this.currentTab !== 'collections';
    },
    tabs() {
      return ['marketplace', 'myofferings', 'mybids', 'collections'];
    },
    currentTab() {
      return this.tabs.includes(this.$route.path.split('/')[3])
        ? this.$route.path.split('/')[3]
        : '';
    },
    tab() {
      const tabs = ['marketplace collections', 'myofferings', 'mybids'];
      return tabs.findIndex((tab) => tab.includes(this.currentTab));
    },
    itemsInCollections() {
      return [
        {
          title: this.$t('market.base.all_collections'),
          to: this.localePath({
            name: `market-preview-${this.route}`,
          }),
          key: 'all_collections',
        },
        {
          title: this.$t('market.my_offerings.title'),
          to: this.localePath({
            name: 'market-preview-myofferings',
          }),
          key: 'my_offerings',
        },
        {
          title: this.$t('market.my_bids.title'),
          to: this.localePath({
            name: 'market-preview-mybids',
          }),
          key: 'my_bids',
        },
      ];
    },
    itemsInOfferings() {
      return [
        {
          title: this.$t('market.market_place.title'),
          to: this.localePath({
            name: `market-preview-${this.route}`,
          }),
          key: 'all_collections',
        },
        {
          title: this.$t('market.my_offerings.title'),
          to: this.localePath({
            name: 'market-preview-myofferings',
          }),
          key: 'my_offerings',
        },
        {
          title: this.$t('market.my_bids.title'),
          to: this.localePath({
            name: 'market-preview-mybids',
          }),
          key: 'my_bids',
        },
      ];
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    route() {
      return this.category.includes('collections')
        ? 'collections'
        : 'marketplace';
    },
    navbarMarketItems() {
      if (this.category.includes('collections')) {
        if (this.onlyCompanyMinter) {
          return this.itemsInCollections.filter(
            (tab) =>
              this.isCompany || (!this.isCompany && tab.key !== 'my_offerings'),
          );
        }
        if (!this.$auth.loggedIn) {
          return this.itemsInCollections.filter(
            (tab) => tab.key !== 'my_offerings' || tab.key !== 'my_bids',
          );
        } else if (
          this.$auth.user.data.is_verified === false ||
          this.$auth.user.data.verification_status !== 'complete'
        )
          return this.itemsInCollections.filter(
            (tab) => tab.key !== 'my_offerings',
          );
        return this.itemsInCollections;
      } else {
        if (this.onlyCompanyMinter) {
          return this.itemsInOfferings.filter(
            (tab) =>
              this.isCompany || (!this.isCompany && tab.key !== 'my_offerings'),
          );
        }
        if (!this.$auth.loggedIn) {
          return this.itemsInOfferings.filter(
            (tab) => tab.key !== 'my_offerings' || tab.key !== 'my_bids',
          );
        } else if (
          this.$auth.user.data.is_verified === false ||
          this.$auth.user.data.verification_status !== 'complete'
        )
          return this.itemsInOfferings.filter(
            (tab) => tab.key !== 'my_offerings',
          );
        return this.itemsInOfferings;
      }
    },
    searchPlaceholder() {
      return this.$t('base.search.placeholder.market', {
        tab: this.$t(
          `market.base.tabs.${
            this.currentTab !== '' ? this.currentTab : 'marketplace'
          }`,
        ),
      });
    },
    categoriesByTab() {
      switch (this.currentTab) {
        case 'marketplace':
          return MARKET_FILTERS.map((filter) => {
            filter.label = this.$t(`market.base.filters.${filter.key}.label`);
            filter.options.map((option) => {
              option.label = this.$t(
                `market.base.filters.${filter.key}.options.${option.key}.label`,
              );
              return option;
            });
            return filter;
          });
        case 'myofferings':
          return MY_OFFERINGS_FILTERS.map((filter) => {
            filter.label = this.$t(`market.base.filters.${filter.key}.label`);
            filter.options.map((option) => {
              option.label = this.$t(
                `market.base.filters.${filter.key}.options.${option.key}.label`,
              );
              return option;
            });
            return filter;
          });
        case 'mybids':
          return MY_BIDS_FILTERS.map((filter) => {
            filter.label = this.$t(`market.base.filters.${filter.key}.label`);
            filter.options.map((option) => {
              option.label = this.$t(
                `market.base.filters.${filter.key}.options.${option.key}.label`,
              );
              return option;
            });
            return filter;
          });

        default:
          return [];
      }
    },
    activeFiltersByTab() {
      switch (this.currentTab) {
        case 'marketplace':
          if (!this.$route.query.category) return MARKET_FILTERS_DEFAULT;
          else {
            const filters = Object.assign({}, MARKET_FILTERS_DEFAULT);
            return Object.assign(filters, {
              categories: [this.$route.query.category],
            });
          }
        case 'myofferings':
          return MY_OFFERINGS_BIDS_FILTERS_DEFAULT;
        case 'mybids':
          return MY_OFFERINGS_BIDS_FILTERS_DEFAULT;
        default: {
        }
      }
    },
    activeTeamFiltersByTab() {
      const defaultTeamFilters = { teams: ['any'] };
      switch (this.currentTab) {
        case 'marketplace':
          if (!this.$route.query.team) return defaultTeamFilters;
          else {
            return Object.assign(defaultTeamFilters, {
              teams: [this.$route.query.team],
            });
          }

        default:
          return defaultTeamFilters;
      }
    },
    loggedUserId() {
      return this.$auth.user ? this.$auth.user.data.id : '';
    },
    isCompany() {
      return (
        this.$auth.user &&
        this.$auth.loggedIn &&
        this.$auth.user.data.user_option === USER_OPTION_COMPANY
      );
    },
    filterApplied() {
      return (
        this.filterByWords.length > 0 ||
        Object.keys(this.appliedFilters).some((key) =>
          this.appliedFilters[key].some((item) => item !== 'any'),
        )
      );
    },
  },
  fetchOnServer: false,
  async fetch() {
    const teams = [
      { key: 'any', label: this.$t('base.all_companies') },
      { key: 'following', label: this.$t('base.following') },
    ];
    const rawCompanies = await this.$api.user.getAllCompanies();
    teams.push(
      ...rawCompanies.map((team) => {
        team['key'] = team.nick;
        return team;
      }),
    );
    this.teams = [...teams];
  },
  methods: {
    handleClickOutside(event) {
      const selectableFilterParent = event.target.closest(
        'selectable-category',
      );
      const selectableFilterId =
        selectableFilterParent !== null
          ? selectableFilterParent.querySelector(
              '[data-key="selectable-category"]',
            ).dataset.id
          : null;
      if (this.toggleSelect === true && selectableFilterId === null)
        this.toggleSelect = false;
    },

    updateFilters(newFilters) {
      const oldFilters = Object.assign({}, this.appliedFilters);
      this.appliedFilters = Object.assign(oldFilters, newFilters);
    },
  },

  mounted() {
    if (this.$route.query.category) {
      this.appliedFilters = { categories: [this.$route.query.category] };
    }
    if (this.$route.query.teams)
      this.appliedFilters = Object.assign(this.appliedFilters, {
        teams: [this.$route.query.teams],
      });
  },
};
