
export default {
  props: {
    filters: {
      type: Array,
    },
    activeTabOnInit: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    appliedFilters: [],
  }),
  watch: {
    appliedFilters(newval) {
      this.onFiltersChange(newval);
    },
    activeTabOnInit(newVal) {
      if (newVal || typeof newVal === 'number') {
        this.appliedFilters.forEach((filter) => this.removeFilter(filter));
        this.applyFilter(this.filters[newVal]);
      }
    },
  },
  mounted() {
    if (this.activeTabOnInit || typeof this.activeTabOnInit === 'number') {
      this.applyFilter(this.filters[this.activeTabOnInit]);
    }
  },
  methods: {
    applyFiltersOnMobile(appliedFilters) {
      this.appliedFilters = appliedFilters;
      this.onFiltersChange(appliedFilters);
    },
    applyFilter(selectedFilter) {
      if (this.appliedFilters.length >= 1) {
        // more than 1 filter if has all or featured, remove it
        if (this.appliedFilters.includes(this.$t('market.base.filters.all')))
          this.removeFilter(this.$t('market.base.filters.all'));
        if (
          this.appliedFilters.includes(this.$t('market.base.filters.products'))
        )
          this.removeFilter(this.$t('market.base.filters.products'));
        if (this.appliedFilters.includes(this.$t('meetings.filters.featured')))
          this.removeFilter(this.$t('meetings.filters.featured'));
      }
      // if new filter already, remove it and if page has all filter add it
      if (this.appliedFilters.includes(selectedFilter)) {
        this.removeFilter(selectedFilter);
        if (this.appliedFilters.length === 0)
          if (
            this.filters.some(
              (filter) => filter === this.$t('market.base.filters.all'),
            )
          )
            this.addFilter(this.$t('market.base.filters.all'));
        if (
          this.filters.some(
            (filter) => filter === this.$t('market.base.filters.products'),
          )
        )
          this.addFilter(this.$t('market.base.filters.products'));
      } else {
        // if new filter clear filters and add it
        if (
          [
            this.$t('market.base.filters.all'),
            this.$t('market.base.filters.products'),
            this.$t('network.filters.default'),
            this.$t('network.filters.company'),
          ].includes(selectedFilter) ||
          this.appliedFilters.find((filter) =>
            [
              this.$t('network.filters.online'),
              this.$t('network.filters.connections'),
            ].includes(filter),
          )
        ) {
          if (
            this.appliedFilters.find((filter) =>
              [
                this.$t('network.filters.online'),
                this.$t('network.filters.connections'),
              ].includes(filter),
            )
          ) {
            this.appliedFilters = this.appliedFilters.filter((filter) =>
              [
                this.$t('network.filters.online'),
                this.$t('network.filters.connections'),
              ].includes(filter),
            );
          } else {
            this.appliedFilters = [];
          }
        }

        this.addFilter(selectedFilter);
      }
      this.onFiltersChange();
    },
    removeFilter(selectedFilter) {
      this.appliedFilters = this.appliedFilters.filter(
        (filter) => filter !== selectedFilter,
      );
    },
    addFilter(selectedFilter) {
      this.appliedFilters.push(selectedFilter);
    },
    onFiltersChange(filters = this.appliedFilters) {
      this.$emit('filters-changed', filters);
    },
  },
};
