import { render, staticRenderFns } from "./CustomSelectableFiltersCopy.vue?vue&type=template&id=20f7fc58&"
import script from "./CustomSelectableFiltersCopy.vue?vue&type=script&lang=js&"
export * from "./CustomSelectableFiltersCopy.vue?vue&type=script&lang=js&"
import style0 from "./CustomSelectableFiltersCopy.vue?vue&type=style&index=0&id=20f7fc58&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/tmp/build_51aa5b7f/components/base/BaseIcon.vue').default,BaseBtn: require('/tmp/build_51aa5b7f/components/base/BaseBtn.vue').default})
